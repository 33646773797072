import { AirlinesApi } from '../../services/Api/AirlinesApi';
import { separateUrl } from '../../services/separateUrl';
import { airlinesInitialState } from './initialStates';

const CLEAR_AIRLINES = 'AIRLINES/CLEAR';
const SET_AIRLINES_LOADED = 'AIRLINES/SET_LOADED';
const SET_AIRLINES_ALL_FIELD = 'AIRLINES/SET_ALL_FIELD';
const SET_AIRLINES_ID = 'AIRLINES/SET_ID';
const SET_AIRLINES_NAME = 'AIRLINES/SET_NAME';
const SET_AIRLINES_CHECK_IN = 'AIRLINES/SET_CHECK_IN';
const SET_AIRLINES_IATA_CODE = 'AIRLINES/SET_IATA_CODE';
const SET_AIRLINES_ICAO_CODE = 'AIRLINES/SET_ICAO_CODE';
const SET_AIRLINES_FS = 'AIRLINES/SET_FS';
const SET_AIRLINES_ACTIVE = 'AIRLINES/SET_ACTIVE';
const SET_AIRLINES_IMAGE = 'AIRLINES/SET_IMAGE';
const SET_AIRLINES_IMAGE_FILE = 'AIRLINES/SET_IMAGE_FILE';
const SET_AIRLINES_FACEBOOK = 'AIRLINES/SET_FACEBOOK';
const SET_AIRLINES_INSTAGRAM = 'AIRLINES/SET_INSTAGRAM';
const SET_AIRLINES_LINKEDIN = 'AIRLINES/SET_LINKEDIN';
const SET_AIRLINES_TWITTER = 'AIRLINES/SET_TWITTER';
const SET_AIRLINES_WEBSITE = 'AIRLINES/SET_WEBSITE';
const SET_AIRLINES_LAST_UPDATE = 'AIRLINES/SET_LAST_UPDATE';

const initialState = airlinesInitialState;

export const getAirlines = (id) => {
  return (dispatch) =>
    AirlinesApi.getAirlinesId(id).then((res) => {
      const newData = { ...res, loaded: true };
      newData.checkIn = separateUrl(newData.checkIn);
      newData.website = separateUrl(newData.website);
      newData.facebook = separateUrl(newData.facebook);
      newData.instagram = separateUrl(newData.instagram);
      newData.linkedin = separateUrl(newData.linkedin);
      newData.twitter = separateUrl(newData.twitter);
      dispatch(setAirlinesAllField(newData));
    });
};
export const clearAirlines = () => {
  return { type: CLEAR_AIRLINES };
};
export const setAirlinesLoaded = (condition) => {
  return { type: SET_AIRLINES_LOADED, payload: condition };
};
export const setAirlinesAllField = (data) => {
  return { type: SET_AIRLINES_ALL_FIELD, payload: data };
};
export const setAirlinesId = (id) => {
  return { type: SET_AIRLINES_ID, payload: id };
};
export const setAirlinesName = (name) => {
  return { type: SET_AIRLINES_NAME, payload: name };
};
export const setAirlinesCheckIn = (checkIn) => {
  return { type: SET_AIRLINES_CHECK_IN, payload: checkIn };
};
export const setAirlinesIataCode = (iataCode) => {
  return { type: SET_AIRLINES_IATA_CODE, payload: iataCode };
};
export const setAirlinesIcaoCode = (icaoCode) => {
  return { type: SET_AIRLINES_ICAO_CODE, payload: icaoCode };
};
export const setAirlinesFS = (fs) => {
  return { type: SET_AIRLINES_FS, payload: fs };
};
export const setAirlinesActive = (active) => {
  return { type: SET_AIRLINES_ACTIVE, payload: active };
};
export const setAirlinesImage = (image) => {
  return { type: SET_AIRLINES_IMAGE, payload: image };
};
export const setAirlinesImageFile = (imageFile) => {
  return { type: SET_AIRLINES_IMAGE_FILE, payload: imageFile };
};
export const setAirlinesFacebook = (facebook) => {
  return { type: SET_AIRLINES_FACEBOOK, payload: facebook };
};
export const setAirlinesInstagram = (instagram) => {
  return { type: SET_AIRLINES_INSTAGRAM, payload: instagram };
};
export const setAirlinesLinkedin = (linkedin) => {
  return { type: SET_AIRLINES_LINKEDIN, payload: linkedin };
};
export const setAirlinesTwitter = (twitter) => {
  return { type: SET_AIRLINES_TWITTER, payload: twitter };
};
export const setAirlinesWebsite = (website) => {
  return { type: SET_AIRLINES_WEBSITE, payload: website };
};
export const setAirlinesLastUpdate = (lastUpdate) => {
  return { type: SET_AIRLINES_LAST_UPDATE, payload: lastUpdate };
};

const airlines = (state = initialState, action) => {
  switch (action.type) {
    case SET_AIRLINES_LOADED:
      return { ...state, loaded: action.payload };
    case SET_AIRLINES_ALL_FIELD:
      return { ...state, ...action.payload };
    case SET_AIRLINES_ID:
      return { ...state, id: action.payload };
    case SET_AIRLINES_NAME:
      return { ...state, names: action.payload };
    case SET_AIRLINES_CHECK_IN:
      return { ...state, checkIn: action.payload };
    case SET_AIRLINES_IATA_CODE:
      return { ...state, iata: action.payload };
    case SET_AIRLINES_ICAO_CODE:
      return { ...state, icao: action.payload };
    case SET_AIRLINES_FS:
      return { ...state, fs: action.payload };
    case SET_AIRLINES_ACTIVE:
      return { ...state, active: action.payload };
    case SET_AIRLINES_IMAGE:
      return { ...state, squareImage: action.payload };
    case SET_AIRLINES_IMAGE_FILE:
      return { ...state, squareImageFile: action.payload };
    case SET_AIRLINES_FACEBOOK:
      return { ...state, facebook: action.payload };
    case SET_AIRLINES_INSTAGRAM:
      return { ...state, instagram: action.payload };
    case SET_AIRLINES_LINKEDIN:
      return { ...state, linkedin: action.payload };
    case SET_AIRLINES_TWITTER:
      return { ...state, twitter: action.payload };
    case SET_AIRLINES_WEBSITE:
      return { ...state, website: action.payload };
    case SET_AIRLINES_LAST_UPDATE:
      return { ...state, lastUpdate: action.payload };
    case CLEAR_AIRLINES:
      return { ...initialState };
    default:
      return state;
  }
};

export default airlines;
