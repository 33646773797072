import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAirport,
  setAirportAddTaxis,
  setAirportRemoveTaxis
} from '../../../../redux/reducers/airport';
import Loader from '../../../Loader';
import { COMPONENT_VARIANT, EVENT_AIRPORT, EVENT_TAXIS, UPDATE_DATA_CHANGE } from '../../../../constants/const';
import TableSelectDetail from '../../../DetailComponent/TableSelectDetail';
import { TaxiApi } from '../../../../services/Api/TaxiApi';

const AirportSelectTaxi = (props) => {
  const stateElement = useSelector((state) => state.airport);
  const [selectedElement, setSelectedElement] = useState(stateElement.taxis);

  const dispatch = useDispatch();

  useEffect(() => {
    if ((!stateElement.id || props.id !== stateElement.id) && props.id !== 'new')
      dispatch(getAirport(parseInt(props.id)));
  }, [props.id]);

  useEffect(() => {
    if (stateElement.taxis.length > 0 && selectedElement.length === 0)
      setSelectedElement(stateElement.taxis);
  }, [stateElement]);

  const onSelect = (element) => {
    checkAddTaxi(element, UPDATE_DATA_CHANGE.update);
  };

  const onDelete = (element) => {
    checkAddTaxi(element, UPDATE_DATA_CHANGE.delete);
  };

  const getFullMassAirportTaxi = () => {
    const returnAirport = [...stateElement.addTaxi];
    stateElement.taxis.map((value) => {
      const findRemoveAirport = stateElement.removeTaxi.find((el) => el.id === value.id);
      if (!findRemoveAirport) returnAirport.push(value);
    });
    return returnAirport;
  };

  const checkAddTaxi = (selectedElement, type) => {
    const findAirportTaxi = stateElement.taxis.find((value) => value.id === selectedElement.id);
    if (type === UPDATE_DATA_CHANGE.update) {
      const findRemoveTaxi = stateElement.removeTaxi.find(
        (value) => value.id === selectedElement.id
      );
      if (!findAirportTaxi) {
        const newAddAirport = [...stateElement.addTaxi];
        newAddAirport.push(selectedElement);
        dispatch(setAirportAddTaxis(newAddAirport));
      }
      if (findRemoveTaxi) {
        const newMassRemove = stateElement.removeTaxi.filter(
          (value) => value.id !== selectedElement.id
        );
        dispatch(setAirportRemoveTaxis(newMassRemove));
      }
    } else {
      const findRemoveAddTaxi = stateElement.addTaxi.find(
        (value) => value.id === selectedElement.id
      );
      if (findRemoveAddTaxi) {
        const newAddAirport = stateElement.addTaxi.filter(
          (value) => value.id !== selectedElement.id
        );
        dispatch(setAirportAddTaxis(newAddAirport));
      }
      if (findAirportTaxi) {
        const newMassRemove = [...stateElement.removeTaxi];
        newMassRemove.push(selectedElement);
        dispatch(setAirportRemoveTaxis(newMassRemove));
      }
    }
  };

  return (
    <Loader loading={!stateElement.loaded} variant={COMPONENT_VARIANT.dark} withMargin={100}>
      <TableSelectDetail
        title={'Taxi'}
        description={'Select taxi companies to add to this airport and click Save.'}
        titleCard={'title'}
        saveData={() => {}}
        request={TaxiApi.getTaxiPage}
        event={EVENT_TAXIS}
        navigateEvent={EVENT_AIRPORT}
        selectedElement={getFullMassAirportTaxi()}
        onSelect={onSelect}
        onDelete={onDelete}
      />
    </Loader>
  );
};

export default AirportSelectTaxi;
