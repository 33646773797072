import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import { COMPONENT_VARIANT, EVENT_AIRLINES } from '../../../../constants/const';
import BlockDetail from '../../../DetailComponent/BlockDetail';
import InputDetail from '../../../DetailComponent/InputDetail';
import ResponseAlert from '../../../DetailComponent/ResponseAlert';
import HeaderDetail from '../../../DetailComponent/HeaderDetail';
import BreadcrumbNavigate from '../../../Breadcrumbs';
import Loader from '../../../Loader';
import { MultipartWrapper } from '../../../../services/MultipartWrapper';
import ImageLoadDetail from '../../../ImageComponent';
import {
  clearAirlines,
  getAirlines,
  setAirlinesActive,
  setAirlinesCheckIn,
  setAirlinesFacebook,
  setAirlinesFS,
  setAirlinesIataCode,
  setAirlinesIcaoCode,
  setAirlinesImageFile,
  setAirlinesInstagram,
  setAirlinesLastUpdate,
  setAirlinesLinkedin,
  setAirlinesLoaded,
  setAirlinesName,
  setAirlinesTwitter,
  setAirlinesWebsite
} from '../../../../redux/reducers/airlines';
import { AirlinesApi } from '../../../../services/Api/AirlinesApi';

const AirlinesDetail = (props) => {
  const airline = useSelector((state) => state.airlines);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setAirlinesLoaded(false));
    if (props.id === 'new') {
      dispatch(clearAirlines());
      dispatch(setAirlinesLoaded(true));
    }
    if ((!airline.id || parseInt(props.id) !== airline.id) && props.id !== 'new')
      dispatch(getAirlines(parseInt(props.id)));
    else {
      dispatch(setAirlinesLoaded(true));
    }
  }, [props.id]);

  const checkFill = (item) => {
    return !item || item === '';
  };

  const checkValidate = () => {
    if (checkFill(airline.iataCode)) return false;
    if (checkFill(airline.name)) return false;
    return true;
  };

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isUpdateChecked, setIsUpdateChecked] = useState(false);
  const [alertChecked, setAlertChecked] = useState(null);

  const validate = () => {
    return true;
  };

  const updateLastChecked = () => {
    setIsUpdateChecked(true);
    AirlinesApi.updateCheckedAirlines(airline.id).then((res) => {
      const DataNow = new Date();
      dispatch(setAirlinesLastUpdate(DataNow.toISOString()));
      setIsUpdateChecked(false);
      setAlertChecked(res);
    });
  };

  const updateAirline = () => {
    let multipart = { ...airline };
    const formData = MultipartWrapper.Airlines(multipart);
    if (validate() && !isLoading) {
      setIsLoading(true);
      if (!airline.id) {
        AirlinesApi.createAirlines(formData).then((res) => {
          if (res.payload) navigate('/' + EVENT_AIRLINES + '/' + res.payload);
          setResponse(res);
          setIsLoading(false);
        });
      } else {
        AirlinesApi.updateAirlines(airline.id, formData).then((res) => {
          setResponse(res);
          setIsLoading(false);
        });
        updateLastChecked();
      }
    }
  };

  return (
    <Loader loading={!airline.loaded} variant={COMPONENT_VARIANT.dark} withMargin={100}>
      <div className="airline-detail">
        {!props.isModal && (
          <BlockDetail>
            <BreadcrumbNavigate name={airline.iataCode} />
          </BlockDetail>
        )}
        <div className="airline-detail-context">
          <HeaderDetail
            name={!airline.iata ? 'new airline' : airline.iata}
            isValidate={checkValidate()}
            updated={airline.updated}
            lastChecked={airline.lastUpdate}
            responsible={airline.responsible}
            requestUpdateChecked={updateLastChecked}
            requestUpdate={updateAirline}
            requestDelete={() => {
              AirlinesApi.deleteAirlines(airline.id);
            }}
            loading={isLoading}
            isUpdateChecked={isUpdateChecked}
            alert={alertChecked}
            isDisabledChecked
            isDisabledDelete
          />
          <ResponseAlert response={response} />
          <BlockDetail>
            <div className="item-data">
              <div className="airline-data">
                <div className="airline-left-context">
                  <InputDetail
                    head="FS"
                    placeholder={'FS'}
                    defaultValue={airline.fs}
                    setField={(value) => {
                      dispatch(setAirlinesFS(value));
                    }}
                    isNecessarily
                    isInput
                  />
                  <InputDetail
                    head="Name"
                    placeholder={'Airline name'}
                    defaultValue={airline.names}
                    setField={(value) => {
                      dispatch(setAirlinesName(value));
                    }}
                    isInput
                  />
                  <InputDetail
                    head="IATA"
                    placeholder={'IATA code'}
                    defaultValue={airline.iata}
                    setField={(value) => {
                      dispatch(setAirlinesIataCode(value));
                    }}
                    isInput
                  />
                  {<InputDetail
                    head="ICAO"
                    placeholder={'ICAO code'}
                    defaultValue={airline.icao}
                    setField={(value) => {
                      dispatch(setAirlinesIcaoCode(value));
                    }}
                    isInput
                  />}
                  <InputDetail
                    head="Website"
                    placeholder={'Airline website'}
                    defaultValue={airline.website}
                    setField={(value) => {
                      dispatch(setAirlinesWebsite(value));
                    }}
                    isWebsite
                    isInput
                  />
                  <InputDetail
                    head="Check-in link"
                    placeholder={'Check-in link'}
                    defaultValue={airline.checkIn}
                    setField={(value) => {
                      dispatch(setAirlinesCheckIn(value));
                    }}
                    isWebsite
                    isInput
                  />

                  <div className="airline-active">
                    <input
                      type="checkbox"
                      className="public-transport-price-fixed-checkbox"
                      onChange={() => {
                        dispatch(setAirlinesActive(!airline.active));
                      }}
                      checked={airline.active}
                    />
                    Active
                  </div>
                </div>
                <div className="airport-image-context">
                  <ImageLoadDetail
                    header={'Airlines photo'}
                    defaultImage={airline.squareImage}
                    imageDetail={airline.squareImageFile}
                    setImageDetail={(value) => {
                      dispatch(setAirlinesImageFile(value));
                    }}
                  />
                </div>
              </div>
            </div>
          </BlockDetail>
          <BlockDetail>
            <div>Social Networks</div>
            <InputDetail
              head="Instagram"
              placeholder={'Instagram link'}
              defaultValue={airline.instagram}
              setField={(value) => {
                dispatch(setAirlinesInstagram(value));
              }}
              isWebsite
              isInput
            />
            <InputDetail
              head="X (Twitter)"
              placeholder={'X (Twitter) link'}
              defaultValue={airline.twitter}
              setField={(value) => {
                dispatch(setAirlinesTwitter(value));
              }}
              isWebsite
              isInput
            />

            <InputDetail
              head="Facebook"
              placeholder={'Facebook link'}
              defaultValue={airline.facebook}
              setField={(value) => {
                dispatch(setAirlinesFacebook(value));
              }}
              isWebsite
              isInput
            />
            <InputDetail
              head="Linkedin"
              placeholder={'Linkedin link'}
              defaultValue={airline.linkedin}
              setField={(value) => {
                dispatch(setAirlinesLinkedin(value));
              }}
              isWebsite
              isInput
            />
          </BlockDetail>
        </div>
      </div>
    </Loader>
  );
};

export default AirlinesDetail;
