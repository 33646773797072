import { PROTOCOL } from '../../constants/const';

const linkInitial = {
  protocol: PROTOCOL.HTTPS,
  url: null
};
export const airportInitialState = {
  loaded: null,
  id: null,
  city: null,
  country: null,
  taxis: [],
  addTaxi: [],
  removeTaxi: [],
  maps: [],
  categories: [],
  description: {},
  iataCode: null,
  lat: null,
  lon: null,
  phone: null,
  information: null,
  timezone: null,
  website: linkInitial,
  mapUrl: linkInitial,
  descriptionImageUrl: null,
  descriptionImageFile: null,
  train: linkInitial,
  metro: linkInitial,
  bus: linkInitial,
  shuttle: linkInitial,
  parking: linkInitial,
  tram: linkInitial,
  other: linkInitial,
  popularity: null,
  otherNames: {},
  instagram: linkInitial,
  twitter: linkInitial,
  facebook: linkInitial,
  updated: null,
  lastChecked: null
};

export const airportMapsInitialState = {
  loaded: null,
  id: null,
  name: null,
  image: null,
  imageFile: null,
  mapType: null,
  category: null
};

export const airlinesInitialState = {
  loaded: null,
  id: null,
  names: null,
  iata: null,
  icao: null,
  fs: null,
  active: false,
  squareImage: null,
  squareImageFile: null,
  facebook: linkInitial,
  instagram: linkInitial,
  linkedin: linkInitial,
  twitter: linkInitial,
  website: linkInitial,
  checkIn: linkInitial
};

export const appInitialState = {
  citesPage: null,
  language: null,
  profile: null,
  mapCategories: null,
  responsible: null
};

export const cityInitialState = {
  loaded: null,
  id: null,
  cityCode: null,
  timezone: null,
  headerPhoto: null,
  headerPhotoFile: null,
  headerPhotoAuthorUrl: null,
  search_field: null,
  country: null,
  otherNames: {}
};

export const countryInitialState = {
  loaded: null,
  id: null,
  countryCode: null,
  currency: null,
  flag: null,
  search_field: null,
  otherNames: {},
  currencyInfo: null
};

export const currencyInitialState = {
  loaded: null,
  id: null,
  currency: null,
  price: null,
  symbol: null
};

export const taxisInitialState = {
  loaded: null,
  id: null,
  order: null,
  title: null,
  webUrl: linkInitial,
  appStoreUrl: linkInitial,
  googleStoreUrl: linkInitial,
  imageUrl: null,
  imageUrlFile: null,
  airports: [],
  addAirportTaxi: [],
  removeAirportTaxi: [],
  urlSchema: null
};

export const rentalInitialState = {
  loaded: null,
  id: null,
  order: null,
  title: null,
  urlSchema: null,
  squareImage: null,
  squareImageFile: null,
  appStoreUrl: linkInitial,
  googleStoreUrl: linkInitial,
  webUrl: linkInitial,
  airport: [],
  addAirport: [],
  removeAirport: []
};

export const pushEventInitialState = {
  loaded: null,
  id: null,
  event: null,
  nameRU: null,
  localization: [],
  newLocalization: []
};

export const weatherStateInitialState = {
  loaded: null,
  id: null,
  name: null,
  languages: null
};

export const publicTransportInitialState = {
  airport: null,
  loaded: null,
  id: null,
  buyTicketUrl: linkInitial,
  color: null,
  frequencyMaximum: null,
  frequencyMinimum: null,
  imagePath: null,
  imagePathFile: null,
  journeyTimeMaximum: null,
  journeyTimeMinimum: null,
  ordering: null,
  priceIsFixed: null,
  priceValue: null,
  type: null,
  names: {},
  subNames: {},
  descriptions: {},
  tags: []
};
